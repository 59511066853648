<template>
  <div class="member-home">
    <MemberNominalHomeOverview />
    <div class="inner-info">
      <img :src="require('/static/icons/person.png')" />
      <span>
        恭喜您注册成功！点击到
        <router-link to="/identify" style="color: #57a3f3">
          认证完善
        </router-link>
        ，补全您的资质
      </span>
    </div>
    <GoodsRelevant />
  </div>
</template>
<script>
import MemberNominalHomeOverview from '@/views/member/home/components/MemberNominalHomeOverview'
// import useCollection from '@/hooks/member/useCollection'

export default {
  name: 'MemberHomePage',
  components: {
    // GoodsItem,
    // GoodsRelevant,
    MemberNominalHomeOverview,
  },
  setup () {
    // const collection = useCollection()

    // return { collection }
  },
}
</script>
<style scoped lang="less">
.member-home {
  :deep(.xtx-carousel) .carousel-btn.prev {
    left: 5px;
  }
  :deep(.xtx-carousel) .carousel-btn.next {
    right: 5px;
  }
}
.inner-info {
  height: 31vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
